import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        getIrigasiMacro(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/irigasi-macro', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        addIrigasiMacro(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('/irigasi-macro/create', data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        updateIrigasiMacro(_, { id, data }) {
            return new Promise((resolve, reject) => {
                axios.put('/irigasi-macro/update/' + id, data).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        deleteIrigasiMacro(_, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/irigasi-macro/delete/' + id).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        selectedAction(_, {action, data}) {
            return new Promise((resolve, reject) => {
                axios.post('/irigasi-macro/selected_action/' + action, { data: data }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        exportReport(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/irigasi-macro/selected_action/export_excel',  { params: _params, responseType: 'blob' }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        ReportByDate(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/irigasi-macro/selected_action/export_pdf', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        getKecamatan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/kecamatan', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },

        importIrigasiMacro(_, file) {
            return new Promise((resolve, reject) => {

                const formData = new FormData();
                formData.append('file', file);
                const headers = { 'Content-Type': 'multipart/form-data' };

                let config = {
                    headers
                }
  
                axios.post('/irigasi-macro/import', formData, config).then(resp => {
                    resolve(resp)
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentase(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/irigasi-macro/persentase', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseBySurvey(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/irigasi-macro/persentase-by-survey', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseByKecamatan(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/irigasi-macro/persentase-by-kecamatan', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        },

        persentaseByKecamatanSurvey(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/irigasi-macro/persentase-by-kecamatan-survey', { params: _params }).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e)
                })
            })
        }
    },
    getters: {

    }

}